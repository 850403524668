String.prototype.removeAccents = function () {
    var set = this.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase()
    return set
}

String.prototype.filenameCompliant = function () {
    const valideFileName = this.removeAccents().replace(/[^a-zA-Z0-9/.]/g, '_')
    //console.log(valideFileName)
    return valideFileName
}

