import { createRouter, createWebHistory } from 'vue-router'

//import {default as navigationVersion} from "../lib/navigatorVersion"

import i18n from '../i18n' 
const I18n = i18n.global

const routes = [
    {
        path: '/',
        name: 'Recording',
        component: () => import('../views/record/record.vue')
    },

    {
        path: '/replay',
        name: 'Replays',
        component: () => import('../views/replays/replays.vue')
    },

    //PROMPTER
    {
        path: '/prompter',
        meta: {
            title: I18n.t('DocumentTitle.prompter'),
        },
        name: 'prompter',
        component: () => import('../views/prompter/prompter.vue')
    },

    //ADMIN DASHBOARD 
    /*
    {
        path: '/admin',
        meta: {
            title: 'Yopbox Admin dashboard',
        },
        name: 'BackEnd',
        component: () => import('../views/backend/backend.vue')
    },
    */
    

    //ERROR
    {
        path: '/error',
        meta: {
            title: I18n.t('DocumentTitle.prompter'),
        },
        name: 'Error',
        component: () => import('../views/error.vue')
    },
    
    { 
        path: "/:catchAll(.*)", 
        beforeEnter: (to, from, next) => {
            next({ name: 'Error' })
        }
    }
]





const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    linkActiveClass: "active", //add "active" class on <router-link> current active
})



//REDIRECT TO DESKTOP VERSION FOR NON MOBILE NAVIGATOR
router.beforeEach(async (to, from, next) => {
    document.title = to.meta.title || I18n.t('DocumentTitle.home')

    /*
    const firstElPath = to.path.split('/').filter(element => element)[0] || null
    
    if (firstElPath == "prompter"){
        next()
    } else if (firstElPath == "mobile" && navigationVersion.mobile == false){
        next({
            path : "/",
            query : to.query
        })
    } else if ( firstElPath != "mobile" && navigationVersion.mobile == true){
        next({
            path : "/mobile",
            query : to.query
        })
    } else {
        next()
    }
    */

    next()
})

export default router