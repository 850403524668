import { createApp } from 'vue'

import axios from 'axios'
import VueAxios from 'vue-axios'
import VueCookies from 'vue3-cookies'
import App from './App.vue'
import I18n from './i18n.js'
document.title = 'Recorder'

//ADD META
import { createMetaManager } from 'vue-meta'

//STORE
import store from "./store"

//ROUTER
import router from './router'

import yopboxuikit from '@yopbox/yopboxuikit'

import {default as navigationVersion} from "./lib/navigatorVersion.js"

import "./lib/filenameCompliant"

const app = createApp(App)

app.use(I18n)
app.use(router)
app.use(store)
app.use(yopboxuikit)
app.use(VueAxios, axios)
app.use(createMetaManager())
app.use(VueCookies);
app.mixin({
  //GLOBAL DATA
  data() {
    return {
      navigationVersion,
      possibleRecordingMimesTypes : [
        'video/webm;codecs=vp8,opus',
        'video/webm;codecs=avc1.424028',
        'video/webm;codecs=avc1.424028, opus',
        'video/mp4; codecs="avc1.4d002a"',
        'video/mp4; codecs="avc1.424028, mp4a.40.2"',
        'video/webm;codecs=h264,opus',
        "video/mp4;codecs=h264,aac",
        "video/mp4;codecs=h264",
        "video/webm;codecs=vp9,opus",
     ],
     NODE_ENV : process.env.NODE_ENV,
    }
  },
  //GLOBAL METHODS
  methods: {
    sleep: function (ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    copyToClipboard(data){
      if (navigator.clipboard){
        navigator.clipboard.writeText(data).then(() => {
            this.Toaster.info(this.$t('Toaster.info.linkCopied'))
        });
      }
    },
    detectExtention(type){
      var extention = "mp4";
      if (/video\/webm/.test(type)) {
          extention = "webm";
      } else if (/video\/mp4/.test(type)) {
          extention = "mp4";
      } else if (/video\/quicktime/.test(type)) {
          extention = "mov";
      }
      return extention
    }
  },
  computed : {
    appURL(){
      return process.env.APP_URL
    }
  }
})
app.mount('#app')

