<template>
  <div>
    <router-view ></router-view>
    <loader v-if="clip_loading || !bridgeConnected"/>
    <span
      class="bridgeConnection"
      v-if="bridgeConnected == false"
      :class="bridgeConnected == true ? 'connected' : ''"
      >{{ bridgeConnected == true ? "Connected" : $t('errors.bridge_off_line') }}</span
    >
    <input id="clipboardhorustest" type="text" style="position: absolute;right: 0;bottom: 0;width: 1px;height: 1px;"/>
    <Toaster />
  </div>
</template>

<script>
//import { default as navigationVersion } from './lib/navigatorVersion'

//Hotjar tracking
import Hotjar from '@hotjar/browser';

//STORE
import Vuex from "vuex";

import loader from "./components/Loader.vue"

export default {
  data() {
    return {
      socketHandled: this.$store.state.socketInstance
    };
  },
  components : {
    loader
  },
  name: "Recorder Yopbox",
  metaInfo: {
    title: "Coucou",
    meta: [
      { charset: "utf-8" },
      {
        name: "apple-mobile-web-app-status-bar-style",
        content: "black-translucent",
      },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
    ],
  },

  created() {
    window.addEventListener("beforeunload", () => {
      this.socketHandled.disconnect(true);
    });

  },
  mounted() {
    if (this.NODE_ENV == "prodcution"){
      //HOTJAR
      const siteId = 3520917;
      const hotjarVersion = 6;
      Hotjar.init(siteId, hotjarVersion);
    }
  },
  computed : {
    ...Vuex.mapGetters([
      "bridgeConnected",
      "clip_loading"
    ])
  }
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --color1: #3eb1c8;
  --color2: #3d545c;
  --color_negatif: #ad302a;
  --color_border: #eaebeb;
  --border_radius: 12px;

  /* ANIMATIONS */
  --easeEnter: cubic-bezier(0.62, 0.01, 0.26, 0.99);
  --easeExit: cubic-bezier(0.47, 0.06, 0.96, 0.4);
  --bounce: cubic-bezier(0.175, 0.885, 0.32, 1.275);

  /* DROP SHADOW */
  --drop_shadow: drop-shadow(10px 0px 20px #00000071);
}
html,
body {
  font-family: "Poppins";
  font-weight: 200;
  font-size: 1rem;
  margin: 0;
  padding: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  color: var(--color2);
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}

html {
  background-color: #f9fafa;
}

.button {
  border-radius: 12px;
  border: 1px solid transparent;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 2.3em;
  padding-right: 2.3em;
  background-color: var(--color1);
  color: white;
  cursor: pointer;
}

.center {
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

.bridgeConnection {
  position: absolute;
  top: 10px;
  right: 10px;
  color: red;
  font-weight: 400;
}
.bridgeConnection.connected {
  color: green;
}
</style>


<style src="./style/panel_animation.css"></style>
<style src="./style/panel.css"></style>
<style src="./style/waitingForBridgeConnection.css"></style>
<style src="./style/bg_circles.css"></style>
<style src="./style/loading_spinner.css"></style>