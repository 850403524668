
function ms2tc(ms) {
    if (ms != undefined){
        var minutes = Math.floor(ms/1000 / 60);
        var seconds = (ms/1000 - minutes * 60).toFixed(0)
        if (minutes < 10) { minutes = "0" + minutes }
        if (seconds < 10) { seconds = "0" + seconds }

        return minutes + ":" + seconds

    } else {
        return '00:00'
    }
}

//DISPLAY SECONDS TO (H)H h (M)M min (S)S s
function renderSeconds(time) {
    let display = ""
    let dateTime = new Date(time * 1000).toISOString()
    let hours = dateTime.substring(11, 13)
    let minutes = dateTime.substring(14, 16)
    let secondes = dateTime.substring(17, 19)
    if (hours != '00') {
      display += Number(hours) + ' h '
    }
    if (minutes != '00') {
      display += Number(minutes) + ' min '
    }
  
    if (secondes != '00') {
      display += Number(secondes) + ' s '
    }
  
    if (display == ""){
      display = '0 s '
    }
    
    return display
  }

module.exports = {
    ms2tc,
    renderSeconds
}