import router from "./../router/index";
import i18n from '../i18n' 
const I18n = i18n.global

export default function createWebSocketPlugin (socket) {
    return store => {
      store.$socket = socket
      //socket.on('message', payload => store.dispatch('receiveMessage', payload))

      socket.on("connect", () => {
        store.state.bridgeConnection = true
      });

      socket.on("disconnect", () => {
        store.state.bridgeConnection = false
      });

      //FORCE TO DISCONNECT
      socket.on("forceDisconnect", () => {
        socket.disconnect(true);
      });

      //REFRESH STATE FROM BRIDGE
      socket.on("state", (bridgeState) => {
        
        //GET PROJECT ID
        if (bridgeState.project_id != undefined && bridgeState.project_id != null && store.state.project_id != bridgeState.project_id){
          store.state.project_id = bridgeState.project_id
          
          //Generate QRCode
          store.dispatch('generatePrompterQrCode')
        }

        //GET TIMELINE UPDATES
        if (bridgeState.project_timeline != undefined) {
          store.state.project_timeline = bridgeState.project_timeline;
        }
        
        //CURRENT CLIP ID
        if (bridgeState.currentClipId != undefined) {
          store.state.currentClipId = bridgeState.currentClipId;
        }
        
        if (bridgeState.currentScriptText != undefined) {
          store.state.currentScriptText = bridgeState.currentScriptText;
        }

        //GET USER SUBSCRIPTION
        store.state.usr_subscription_name = bridgeState.usr_subscription_name;
        store.state.usr_credits = bridgeState.usr_credits;

      })



      socket.on("no_video_found", () => {
        window.Toaster.error(I18n.t("Toaster.error.videoNotFound"));
        socket.disconnect(true);
        router.push({
          name : "Error",
          query: {
            ...router.currentRoute._value.query,
            error: "Video introuvable",
          },
        });
      });
    }
}