import i18n from '../i18n' 
const I18n = i18n.global

export default {
    bridgeConnected: (state) => {
        return state.bridgeConnection
    },

    clip_loading: (state) =>{
      return state.clip_loading
    },

    creativeSpaceUrl: (state) =>{
        const appURL = process.env.APP_URL
        if (state.project_id != null){
            return `${appURL}/edit/${state.project_id}`
          } else {
            return appURL
          }
    },

    prompterUrl: (state) =>{
        return `${process.env.RECORDER_URL}/prompter?project_id=${state.project_id}&language=${I18n.locale}`;
    },

    project_id: (state) =>{
      return state.project_id
    },

    currentClip: (state) =>{
      return state.currentClip
    },

    currentClipId: (state) =>{
        return state.currentClipId
    },

    currentScriptText: (state) =>{
      return state.currentScriptText
  },

    project_timeline: (state) =>{
        return state.project_timeline
    },

    uploads: (state) =>{
        return state.uploads
    },

    localRecords: (state) =>{
      return state.localRecords
    },

    usr_credits: (state) =>{
      return state.usr_credits
    },

    internal_prompter_enable: (state) =>{
      return state.internal_prompter_enable
    },
    internal_prompter_speed: (state) =>{
      return state.internal_prompter_speed
    },
    internal_prompter_size: (state) =>{
      return state.internal_prompter_size
    },

    project_timeline_mixed_with_local_data: (state) =>{
        return state.project_timeline.flatMap((clip) => {
          //SEARCH MATCH WITH UPLOAD LIST
          const upload = state.uploads.find(
            (upload) => upload.clip_id == clip._id
          );

          clip.upload_progress = (upload?.progress != undefined ? upload?.progress : null)
  
          return clip;
        });
    },

    //VERIFIY IF CLIP IS SAVED
    clipIsSaved: (state, getters) => (clip_id) => {
        //FIND CLIP
        const clip = getters.project_timeline_mixed_with_local_data.find(
          (clip) => clip._id == clip_id && clip.clip_type == 1
        );

        if (clip == undefined) {
          return false;
        }

        return (clip.media_id != null)
        
        /*
        if ( clip.upload_progress == 100 && clip.media_id != null) {
          return true;
        } else if (clip.upload_progress == 0 && clip.media_id != null) {
          return true;
        } else {
          return false;
        }
        */
    },

    //CLIP IS IN PROCESSING (TRANSCODE)
    clipInProcessing: (state, getters) => (clip_id) => {
        //FIND CLIP
        const clip = getters.project_timeline_mixed_with_local_data.find(
            (clip) => clip._id == clip_id
          );
  
          if (clip == undefined) {
            return false;
          }

          return (clip.upload_progress == 100)

          //return (clip.upload_progress == 100 && clip.media_id == null)
    },

    clip_recorded_count: (state, getters) =>{
        return getters.project_timeline_mixed_with_local_data.filter((clip) => {
        //return state.project_timeline.filter((clip) => {
          if (clip.clip_type == 1){
            return (clip.media_id != null || clip.upload_progress != null)
          }
        }).length
    },

    clip_to_record_count: (state, getters) =>{
      return getters.project_timeline_mixed_with_local_data.filter(clip => clip.clip_type == 1).length
    },

    recording_time_limit : (state) =>{
      switch (state.usr_subscription_name){
        case null :
          return 5 * 60 * 1000 //5 min

        case "starter" :
          return 5 * 60 * 1000 //5 min

        case "pro" :
          return 20 * 60 * 1000 //20 min

        case "business" :
          return 60 * 60 * 1000 //60 min

        default:
          return 5 * 60 * 1000 //5 min
      }
    },

    record_limitation_message: (state, getters) =>{

      let plan_name = I18n.t("Record.record_limit.trial_period")
      
      switch (state.usr_subscription_name.toLowerCase()){
        case null :
          plan_name = I18n.t("Record.record_limit.trial_period")
          break;

        case "starter" :
          plan_name = I18n.t("Record.record_limit.starter_plan")
          break;

        case "pro" :
          plan_name = I18n.t("Record.record_limit.pro_plan")
          break;

        case "business" :
          plan_name = I18n.t("Record.record_limit.business_plan")
          break;

        default:
          plan_name = I18n.t("Record.record_limit.trial_period")
          break;
      }

      return `<p style="margin-left:10px; margin-right:10px; font-weight:300">${I18n.t("Record.record_limit.message", {"plan_name" : plan_name, "minutes_limit" : getters.recording_time_limit/60000 })}</p>`
    }
}