import i18n from "../i18n";
const I18n = i18n.global;
import axios from "axios";

import router from "./../router/index";

//import {default as navigationVersion} from "../lib/navigatorVersion.js"
import {renderSeconds} from "../lib/time.js"


export default {
  emit_discovering_socket_event: ({ state, dispatch }) => {
    state.socketInstance.emit(
      "discover",
      {
        project_id: router.currentRoute._value.query.project_id.replace(
          /\//g,
          ""
        ),
        clip_id: router.currentRoute._value.query.clip_id.replace(
          /\//g,
          ""
        ),
      },
      (response) => {
        //IF A ROOM ALREADY EXIST, ASK TO BECOME NEW RECORDER
        if (response.roomExists == true && response.recorderExists == true) {
          window.Toaster.prompt(
            {
              title: I18n.t("Toaster.prompt.recorderExists.title"),
              message: I18n.t("Toaster.prompt.recorderExists.message"),
              validate: I18n.t("Toaster.prompt.recorderExists.validate"),
              cancel: I18n.t("Toaster.prompt.recorderExists.cancel"),
            },
            (response) => {
              if (response == "validate") {
                dispatch("joinRoom");
              }
            }
          );
        } else {
          dispatch("joinRoom");
        }
      }
    );
  },

  joinRoom: ({ state }) => {
    state.clip_loading = true
    state.socketInstance.emit(
      "bonjour",
      {
        mode: "recorder",
        userAgent: navigator.userAgent || null,
        project_id: router.currentRoute._value.query.project_id.replace(
          /\//g,
          ""
        ),
        clip_id: router.currentRoute._value.query.clip_id.replace(
          /\//g,
          ""
        ),
      },
      (response) => {
        state.clip_loading = false
        if (response.status >= 400) {
          router.push({
            name: "Error",
            query: {
              ...router.currentRoute._value.query,
              error: response.message,
            },
          });
        } else {

          let clip = response.clip
          state.currentClip = clip;
          
          //IF CLIP IS ALREAYD RECORDED => OPEN REPLAY PANEL
          if (clip.media_id != null) {
            let urlToAuthenticate = `/medias/${clip.media_id}/url`
            state.socketInstance.emit("createShortLivedTokenUrl", urlToAuthenticate, async (url) => {
              
              state.localRecords = [{
                name : I18n.t("Replay.currentlyInProject"),
                url,
                clip_id : clip._id,
                duration : clip.media?.media_duration || null,
                validated: true
              }];

              router.push({
                path: "replay",
                query: {
                  ...router.currentRoute._value.query
                },
              });

              //FETCH SIGNED URL TO GET THUMBNAIL MEDIA PRE-SIGNED URL
              let thumbnail = await axios.get(url).then(res =>{ return res.data.media_thumbnail_url}).catch(()=>{console.warn("Can't fetch media thumbnail")})
              state.localRecords[0].thumbnail = thumbnail
             
              
            });
          } else {
            state.localRecords = [];
          }
        }
      });
  },

  //GENERATE PROMPTER LINK QR CODE
  generatePrompterQrCode: ({ state }) => {
    if (state.project_id != null) {
      const prompterUrl = `${process.env.RECORDER_URL}/prompter?project_id=${state.project_id}&language=${I18n.locale}`;
      //console.log("generate new qr code for ", prompterUrl);
      state.socketInstance.emit("generateQRCode", prompterUrl, (res) => {
        if (res != null) {
          state.prompterQrCode = res;
        }
      });
    }
  },

  generateRecordsBlobThumbnails: ({ state }) => {
    state.localRecords.forEach((localRecord, index) => {
      if (localRecord.record_blob_file != undefined) {
        const canvas = document.createElement("canvas");
        const video = document.createElement("video");

        // this is important
        video.autoplay = true;
        video.muted = true;
        video.crossOrigin = "anonymous";
        video.src = window.URL.createObjectURL(localRecord.record_blob_file);

        video.onloadeddata = () => {
          let ctx = canvas.getContext("2d");

          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;

          ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
          video.pause();
          try {
            state.localRecords[index].thumbnail = canvas.toDataURL("image/png");
          } catch {
            console.debug("Impossible te generate thumbnail");
          }
        };
      }
    });
  },

  //UPLOAD TAKE ON API
  uploadTake: ({ state }, take) => {
    return new Promise((resolve, reject) => {
      (async ()=>{
        try {
          const clip_id = take.clip_id;
          
          if (state.usr_credits < Math.floor(take.duration/1000) ){
            throw "notEnoughCredit"
          } else {

            let takeDurationRounded = Math.floor(take.duration/1000)

            //If take is shorter than 15s, and credits remaining is less than 15s
            if (takeDurationRounded < 15 && state.usr_credits >= 15){
              takeDurationRounded = 15
            } else if (takeDurationRounded < 15){  // Sauf s'il te reste moins de 15s de crédit, là on te prend tout ton crédit restant
              takeDurationRounded = state.usr_credits
            }

            let credit_remaining_after = state.usr_credits - takeDurationRounded

            await new Promise((resolve, reject) =>{
              window.Toaster.prompt(
                {
                  title: I18n.t("Toaster.prompt.youWillRemain.validateYourTake"),
                  message: I18n.t("Toaster.prompt.youWillRemain.youWillRemain", {time : renderSeconds(credit_remaining_after)}),
                  validate: I18n.t("Toaster.prompt.youWillRemain.validate"),
                  cancel: I18n.t("Toaster.prompt.youWillRemain.cancel"),
                },
                (response) => {
                  if (response == "validate") {
                    resolve()
                  } else {
                    reject ("canceled")
                  }
                }
              );
            })
          }
          
          let url = await new Promise((resolve2) =>{
            state.socketInstance.emit("createUploadSecuredUrl", clip_id, (urlReturned) => {
              if (urlReturned != undefined && urlReturned != null) {
                resolve2(urlReturned)
              } else {
                throw "Can't create upload securedUrl"
              }
            })
          })

          //FIND THERE IS ALREADY A UPLOAD FROM THIS CLIP => REMOVE IT
          const uploadIndexFound = state.uploads.findIndex(
            (upload) => upload.clip_id === clip_id
          );
          if (uploadIndexFound > -1) {
            // only splice array when item is found
            state.uploads.splice(uploadIndexFound, 1); // 2nd parameter means remove one item only
          }

          //CREATE UPLOAD ELEMENT in UPLOAD LIST
          const uploadIndex = state.uploads.push({ clip_id }) - 1;

          state.uploads[uploadIndex].progress = 0;
          //this.uploads[uploadIndex].media_id = null

          //UPLOAD
          var firstEvt = true;
          let form = new FormData();
          form.append("file", take.record_blob_file);
          form.append("media_type", take.media_type);
          form.append("metadata", JSON.stringify(take.metadata));

          await axios.post(url, form, {
            //REPORT UPLOAD PROGRESS
            onUploadProgress: (progressEvent) => {
              state.uploads[uploadIndex].progress = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              if (firstEvt && state.uploads[uploadIndex].progress > 1) {
                firstEvt = false;
                console.debug('Uploading in progress')
              }
            }
          })
          .then((res) => {
            window.Toaster.success(I18n.t("Toaster.success.videoSave"));
            state.uploads[uploadIndex].progress = null;

            resolve();
            //Clear Uploads
            var intervaler = setInterval(() => {
              const clip = state.project_timeline.find(
                (c) => c._id === clip_id
              );
              if (clip.media_id == res.data.media_id) {
                state.uploads.splice(uploadIndex, 1);
                clearInterval(intervaler);
              }
            }, 2000);
          })
          .catch((e) => {
            if (e.response?.data){
              throw e.response.data
            } else {
              throw e
            }
          });

        } catch (e){
          if (e == "notEnoughCredit"){
            window.Toaster.error({
              message : I18n.t('Toaster.error.notEnoughCredit', {credit : renderSeconds(state.usr_credits)}),
              //duration : 10000
            })
          } else if (e != "canceled"){
            window.Toaster.error(I18n.t("Toaster.error.takeNotSave"));
          }
          reject(e)
          
          /*
          if (!navigationVersion.mobile){
            state.panel = "replay";
          } else {
            router.push({
              path: "/replays",
              query: {
                ...router.currentRoute._value.query
              },
            });
          }
          */
        }

      })()
    });
  },

  //PROMPTER
  changePrompterSpeed: ({ state }, prompter_speed) => {
    state.socketInstance.emit("prompter", {
      prompter_speed: Number(prompter_speed),
    });
  },
  changePrompterSize: ({ state }, prompter_size) => {
    state.socketInstance.emit("prompter", {
      prompter_size: Number(prompter_size),
    });
  },
  changePrompterFlip: ({ state }, prompter_flip) => {
    state.socketInstance.emit("prompter", {
      prompter_flip,
    });
  },
};
