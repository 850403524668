import Vuex from 'vuex'
import Cookies from 'js-cookie'

//WEB SOCKET
import createWebSocketPlugin from '../lib/websocketStorePlugin'
import socket from '../lib/socket'
const websocketPlugin = createWebSocketPlugin(socket)

import getters from "./getters" 
import actions from "./actions"

export default new Vuex.Store({
    plugins: [websocketPlugin],
    state () {
        return {
          bridgeConnection : false,
          clip_loading : false,
          socketInstance : socket,
          project_id : null,
          project_timeline : [],
          currentClipId : null,
          currentClip : null,
          currentScriptText : "",
          prompterQrCode : null,
          uploads : [],
          localRecords : [],
          panel : null,

          //INTERNAL PROMPTER
          internal_prompter_enable: (() => {
            let cookie = Cookies.get("internal_prompter_enable");

            if (cookie != null) {
              return (cookie == "true" ? true : false)
            } else {
              return true;
            }
          })(),
          internal_prompter_speed:
            Cookies.get("internal_prompter_speed") != null
              ? Cookies.get("internal_prompter_speed")
              : 3,
          internal_prompter_size:
            Cookies.get("internal_prompter_size") != null
              ? Cookies.get("internal_prompter_size")
              : 30,
          
          usr_subscription_name : null,
          usr_credits : null
        }
      },
    getters,
    actions
  })