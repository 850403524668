<template>
    <div class="y_loader_container">
        <div ref="lottieContainer" class="y_loader"></div>
        <h1>{{$t('Home.connection')}}...</h1>
    </div>
</template>



<script>
import lottie from "lottie-web";
import y_loader from "../assets/lotties/y_loader_3.json";

export default {
    data() {
        return {
            anim: null,
        };
    },
    mounted(){
        this.anim = lottie.loadAnimation({
          container: this.$refs.lottieContainer,
          renderer: "svg",
          animationData: y_loader,
          loop: true,
          autoplay: true
        })
    }
}
</script>

<style>
.y_loader_container{
    z-index: 10;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: #ffffffba;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.y_loader{
    width: 200px;
    height: 200px;
}
</style>